<template>
  <v-card class="highlight" elevation="0" height="90vh" color="transparent">
    <v-hover v-slot="{ hover }">
      <v-card height="50vh" elevation="0" color="transparent">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="auto">
            <v-row class="mx-auto" v-animate-css="getAnimation('fadeIn', 1800)">
              <v-card flat class="mx-16" height="50vh" width="50vh" elevation="0" color="transparent">

                <v-img class="mb-16" max-width="500" alt="Adrian photo" :src="require('@/assets/imgs/photo.webp')"
                  :lazy-src="require('@/assets/imgs/photo_blur.webp')">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-1" aria-label="Loading icon"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

              </v-card>
            </v-row>
          </v-col>

          <v-col>
            <div class="ma-16">
              <h1 class="intro-heading" :style="'font-size: ' + textSize + ';'"
                v-animate-css="getAnimation('fadeInLeft', 200)">Hi, my name is <span class="intro-name">Adrian
                  Nieć,</span></h1>

              <p v-if="$vuetify.breakpoint.smAndDown" class="heading mt-5 white--text" :style="'font-size: ' + textSize + ';'"
                v-animate-css="getAnimation('fadeInLeft', 400)">
                <v-btn class="intro-name white--text" @click="loadDialog" outlined>
                  Read more
                </v-btn>
                about me.
              </p>
              <v-componenet :is="dialogComponent" v-bind:text="text" v-if="isDialogOpen"
                @close="isDialogOpen = false"></v-componenet>

              <vue-typed-js :strings="['CLOUD ENGINEER', 'WEB DEVELOPER', 'DEVOPS', 'LINUX ADMINISTRATOR']" :loop="true" :typeSpeed="90"
                :backSpeed="50" :startDelay="3400" :backDelay="3000" :fadeOut="true" :showCursor="true"
                :cursorChar="'_'">
                <h1 class="intro-heading" :style="'font-size: ' + textSize + ';'">
                  <p v-animate-css="getAnimation('fadeInLeft', 600)">I'm a <span
                      class="typing intro-occupation intro-name"></span>
                  </p>
                </h1>
              </vue-typed-js>
            </div>
          </v-col>
        </v-row>

        <v-expand-transition v-if="!$vuetify.breakpoint.smAndDown">
          <v-card v-if="hover" class="d-flex transition-fast-in-fast-out background v-card--reveal-about white--text"
            style="height: 150%;">

            <v-card-text>
              <v-row class="mt-1 d-flex align-center justify-center">
                <v-col :cols="$vuetify.breakpoint.lgAndUp ? '9' : 'auto'">
                  <p class="ma-5 highlight-text" style="font-size: 1.5em;"
                    v-animate-css="getAnimation('fadeInUp', 100, 1000)">
                    Let our story begins</p>
                  <p v-for="(part, index) in text" :key="index"
                    v-animate-css="getAnimation('fadeIn', 200, 500 + (1000 * index))">
                    {{ part }}</p>
                  <skills v-if="!$vuetify.breakpoint.smAndDown" class="my-16"
                    v-animate-css="getAnimation('flipInX', 1000, 1500)" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-hover>

    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="0" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="3" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="5" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="7" />
        </g>
      </svg>
    </div>

    <v-card class="background" height="50vh" elevation="0">
      <v-row align="center" justify="center">
        <v-card-title class="primary--text" style="font-size: 1.3em;" v-animate-css="getAnimation('wobble', 8000, 1500)"
          id="goProjects">Explore projects</v-card-title>
      </v-row>

      <v-row align="center" justify="center">
        <v-card-subtitle>
          <v-btn @click="$vuetify.goTo('#projects')" large fab class="white--text intro-arrow highlight v-btn-no-shadow"
            v-animate-css="getAnimation('tada', 500, 8000, 'infinite')">
            <v-icon aria-label="Go to projects" role="img" aria-hidden="false" size="2em">{{ mdiChevronDown }}</v-icon>
          </v-btn>
        </v-card-subtitle>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import Skills from '@/components/Skills';
import AboutDialog from '@/components/AboutDialog';
import { animateMixin } from "@/mixins/animate"
import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'Intro',
  components: {
    Skills,
    AboutDialog,
  },
  mixins: [animateMixin],
  data: () => ({
    text: {
      1: "In a realm where systems run wild and deployments threaten to break, the only engineer you need to tame the chaos. Armed with the power of Python and the wisdom of all fallen Linux administrators, this automation champion weaves scripts like spells, turning toil into triumph. With Terraform as a trusted staff to summon infrastructure from code, bending clouds (AWS | Azure | GCP) to his will. No downtime beast is too fierce, no pipeline too tangled. He roams the multicloud expanse, bringing order to the unpredictable and crafting elegant solutions from the storms of complexity. His mission? To automate the un-automatable and conquer inefficiency, deployment by the deployment.",
      2: "Meet Adrian - Cloud Administrator at Hostersi. Operating at the first line of support consistently addresses a wide spectrum of intricate issues. This role has afforded me a comprehensive understanding of contemporary technologies, including, but not limited to: Elastic Stack, Kubernetes, and Docker. I've honed my expertise in infrastructure as code management through the utilization of Terraform. Moreover, I have had the the privilege of working with diverse cloud services, within various cloud providers such a AWS, Azure and GCP. Every day at work presents a fresh set of challenges which fuel my enthusiasm for continual advancement in a professional context.",
    },
    dialogComponent: null,
    isDialogOpen: false,
    mdiChevronDown,
  }),
  methods: {
    loadDialog() {
      this.dialogComponent = "AboutDialog"
      this.isDialogOpen = true
    },
  },
  computed: {
    textSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '1.2em'
        case 'xl': return '3em'
        default: return '2em'
      }
    },
  },
}
</script>
